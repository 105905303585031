import React, { useEffect } from "react";
import { useState } from "react";
import "../../StyleSheets/Table/Table.scss";
import { useGlobalFilter, useTable } from "react-table";
import axios from "axios";
import { url1 } from "../../content/data";
const Table = (props) => {
  const {
    data,
    columns,
    pagesize,
    setLoader,
    loader,
    tableRefresh,
    setTbaleRefresh,
    setCurrentpage,
    setLoadingData1,
    setUserDatas,
    limits,
  } = props;

  const [currentpage, setCurrentpages] = useState(1);
  const [visiblepages, setVisiblePages] = useState([1]);
  const [topage, setoPages] = useState();

  const [search, setSearch] = useState();
  const [isActive, setisActive] = useState(false);

  const handleSearch = (e) => {
    const searchFiled = e.target.value;
    setSearch(searchFiled);

    async function searchApi() {
      await axios({
        method: "post",
        url: url1 + `search`,
        data: { key: e.target.value },
      })
        .then((response) => {
          // console.log(response.data.data, "search ");
          setLoader(true);
          setLoadingData1(true);
          // setDatas(response.data.data)
          setTbaleRefresh(true);
          setUserDatas(response.data.data);

          // console.log(tableRefresh,"res");
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
    if (e.target.value != "") {
      searchApi();
    }
  };
  const handleNextPage = () => {
    if (currentpage === 1) {
      const newPageNumber = currentpage;
      handlePageChange(newPageNumber);
    } else {
      const newPageNumber = currentpage + 1;
      handlePageChange(newPageNumber);
    }
  };
  const handlePrevious = (e) => {
    const currentpage = visiblepages[0];
    if (currentpage === 1) {
      // setCurrentpages(1);
      // console.log(currentpage, newPageNumber, "sss");
      const newPageNumber = currentpage;
      handlePageChange(newPageNumber);
    } else {
      const newPageNumber = currentpage - 1;
      handlePageChange(newPageNumber);
    }
  };
  
  const handlePageChange = (pageNumber) => {
    setCurrentpage(pageNumber);
    const nowvisiable = [];
    if (limits === currentpage) {
      for (let i = pageNumber; i <= pageNumber + 4; i++) {
        if (i <= pagesize) {
          nowvisiable.push(i);
        }
      }

      setVisiblePages(nowvisiable);

      setoPages(pageNumber);
      setisActive(!isActive);
    }
  };

  // const handlePageChange = (selectedObject) => {
  //   setCurrentpages(selectedObject.selected + 1);
  // };

  const SetpaginationLinks = () => {
    const paginationLinks = [];
    for (let i = 0; i < visiblepages.length; i++) {
      if (visiblepages[i] <= pagesize) {
        paginationLinks.push(
          <li
            key={visiblepages[i]}
            className={`page-item activesty ${
              currentpage === visiblepages[i] ? "active" : ""
            }`}
          >
            <span
              className="page-link active  selectedpge "
              onClick={() => handlePageChange(visiblepages[i])}
            >
              {visiblepages[i]}
            </span>
          </li>
        );
      } else {
        // paginationLinks.push(
        //   <li key={visiblepages[i]} className='page-item'>
        //   </li>
        // )
      }
    }
    return paginationLinks;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
  });

  return (
    <>
      <div className="searchs ">
        <div className="SEA-RANK">
          <input
            class=" searchInput"
            placeholder="Search.."
            onChange={handleSearch}
          />
        </div>
      </div>

      {data != undefined && (
        <div className="forTheTable">
          <div className="table-responsive reactTable my-4">
            <table className="table table1" {...getTableProps()}>
              <thead className="Thead_content">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="text-center TableHead"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className="PageSCROL ">
        <div className="panginations  ">
          <div className="showingindex ">
            <span className="SHOWINDEX1">
              Showing {currentpage} to {pagesize} Entries
            </span>
          </div>

          <div className="paginpart">
            <nav aria-label="Page navigation example" className="NAV_PART">
              <ul class="pagination MAIN_PAGES justify-content-end">
                <li
                  className={`page-item NP_P  Previous ${
                    currentpage === 1 ? "disabled" : ""
                  }`}
                >
                  <a
                    class={`page-link pages ${
                      currentpage === 1 ? "disabled-link" : ""
                    }`}
                    href="#"
                    tabindex="-1"
                    onClick={handlePrevious}
                  >
                    Previous
                  </a>
                </li>
                {SetpaginationLinks()}
                <li
                  className={`page-item NP_P  next ${
                    currentpage === pagesize ||
                    currentpage === pagesize - 1 ||
                    currentpage === 1
                      ? "disabled"
                      : ""
                  }`}
                >
                  <span
                    class={`page-link pages ${
                      currentpage == pagesize ||
                      currentpage === pagesize - 1 ||
                      currentpage === 1
                        ? "disabled-link"
                        : ""
                    }`}
                    onClick={handleNextPage}
                  >
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
