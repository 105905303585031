// import React from "react";
// import "../../StyleSheets/Login/Forget.scss"
// import pslamsGif from "../../assets/images/pslamsGif.gif";
// import saveAll from "../../assets/images/saveAll.png";

// const Forget = () => {
//   return (
//     <div className="forget_wrapper">
//       <div>
//         <div className="outbor">
//           <nav nav className="navbar navbar-expand-sm">
//             <div className="container-fluid navbarHeadC">
//               <img className="saveAllLogo" src={saveAll} alt="saveallimg" />
//             </div>
//             <div id="navbarCollapse" className="collapse navbar-collapse ">
//               <div className="pslamLogo">
//                 <img src={pslamsGif} alt="pslam" width="54px" />
//               </div>
//             </div>
//           </nav>
//         </div>
//       </div>
//       <div class="container kkkkk">
//         <div class="row">
//           <div class="col-md-4 col-md-offset-4">
//             <div class="panel panel-default">
//               <div class="panel-body">
//                 <div class="text-center">
//                   <h2 class="locklock">
//                     <i class="fa fa-lock fa-4x"></i>
//                   </h2>
//                   <h2 class="text-center">Forgot Password?</h2>
//                   <p>Enter your email address to reset your password.</p>
//                   <div class="panel-body">
//                     <form
//                       id="register-form"
//                       role="form"
//                       autocomplete="off"
//                       class="form"
//                       method="post"
//                     >
//                       <div class="form-group">
//                         <div class="input-group">
//                           <span class="input-group-addon">
//                             <i class="glyphicon glyphicon-envelope color-blue"></i>
//                           </span>
//                           <input
//                             id="email"
//                             name="email"
//                             placeholder="email address"
//                             class="form-control"
//                             type="email"
//                           />
//                         </div>
//                       </div>
//                       <div class="form-group">
//                         <input
//                           name="recover-submit"
//                           class="btn btn-lg btn-block submit-btn"
//                           value="Reset Password"
//                           type="submit"
//                         />
//                       </div>

//                       <input
//                         type="hidden"
//                         class="hide"
//                         name="token"
//                         id="token"
//                         value=""
//                       />

//                       <p>Please Check Your Mail...</p>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Forget;


import React, { useState } from "react";
import "../../StyleSheets/Login/Forget.scss";
import saveAll from "../../assets/images/saveAll.png";
import { Margin } from "@mui/icons-material";
import axios from "axios";
import validator from 'validator';
import isEmail from 'validator/lib/isEmail';
import { url1 } from "../../content/data";

const Forget = () => {
  const [resetEmailValue, setResetEmailValue] = useState("");
  const [Vible, setvisible] = useState(false);
  const [email, setemail] = useState(false)
  let [emailERR, setEmailErr] = useState(false);

  const resetEmailChange = (e) => {
    setResetEmailValue(e.target.value.trim());
    if(validator.isEmail(resetEmailValue)){
      setemail(true)
      console.log(validator.isEmail(resetEmailValue));
    }; 
    if (resetEmailValue === undefined) {
      setEmailErr(true)

    }
  };
  // -----SET---TO DISPLAY---THE MAIL---VERIFY----
  const toReset = () => {

    if (!email) {
      setEmailErr(true)
      }


  }
  var urlFor = url1+"forgetpwd";
  let [invalidEmail , setInvalid] = useState()
  // ---------- send a--mails------------------
  const Checkmail = () => {
    console.log(email, "email");
    if (email) {
      setEmailErr(false)
      setvisible(true)
      console.log(Vible,"vvv");
     
        const respo = axios({
          method: "post",
          url: urlFor,
          data: {
            email: resetEmailValue,
          }
        }).then((respo)=>{
          console.log(respo, "ds");
          setvisible(true)
          setInvalid(respo.status)
     
        }).catch((err)=>{
          console.log(err.response.status,"err");
           invalidEmail = err.response.status
        })

    }
  
  }

  return (

      <>

    <div className="forget_wrapper">
      <div className="outbor">
        <nav className="navbar navbar-expand-sm">
          <div className="container-fluid navbarHeadC">
            <img className="saveAllLogo" src={saveAll} alt="saveallimg" />
          </div>
        </nav>
      </div>
      <div className="main">
        <div className="middle">
          <div className="inner1">
            <h2>Forgot Password?</h2>
            <p>Enter your email address to reset your password.</p>
          </div>
          <div className="inner2">
            <form
              id="register-form"
              role="form"
              autoComplete="off"
              className="form"
              method="post"
            >
              <input
                id="email"
                name="email"
                placeholder="email address"
                className="email"
                type="email"
                value={resetEmailValue}
                onChange={resetEmailChange}
              />
              {emailERR &&
                <div
                  className='text-left'
                  style={{ color: "red", fontSize: "12px" }}
                >Enter a email</div>
              }

            </form>
            <br />
            <button onClick={() => {
              toReset()
              Checkmail()
            }}> Reset Password</button>
          </div>
          { invalidEmail != undefined && Vible && invalidEmail == 200 ? (
              // {Vible &&(
            <div>
              <p>Please Check Your Mail..{Vible}.</p>

            </div>
          //  ) }
            ):(
              <>
              <small>Invalid Email </small>
              </>
              )}

          {/* } */}
        </div>
      </div>
    </div>

  
      </>
   
  );
};

export default Forget;

