import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import { url1 } from "../../content/data";
import SideBar from "../SideBar/SideBar";
import axios from "axios";
import Table from "../TableCom/Table";
import "../../StyleSheets/childFeature.scss";
import { error } from "jquery";
import { Button } from "@material-ui/core";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import Popup from "./popup";
import ConformationPopup from "./ConformationPopup";
import Swal from "sweetalert2";
const ChildFeatured = (props) => {
  const [loader, setLoader] = useState(true);
  const [userDatas, setUserDatas] = useState();
  const [currentpage, setCurrentpage] = useState(1);
  const [limited, setLimited] = useState(10);
  const [toRender, setToRender] = useState(false);

  const Swal = require("sweetalert2");
  //  -------------to-----Remove---the status------
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [child, setChild] = useState(null);
  const [featured, setFeatured] = useState(null);
  const [feedBack, setFeedBack] = useState("");
  const [feedBackStatus, setFeedBackStatus] = useState(false);
  const [loadingData1, setLoadingData1] = useState(true);
  const [feedbackPopup, setFeedbackPopup] = useState(0);
  const [feedbackPopupToggle, setFeedbackPopupToggle] = useState(0);
  const [featuredStatus, setFeaturedStatus] = useState({});
  const [childrenFeaturedStatus, setChildrenFeaturedStatus] = useState({});
  const [verify, setVerify] = useState("");
  const [VerifyToggle, setVerifyToggle] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const [tableRefresh, setTbaleRefresh] = useState(false);
  let [activeVideo, setActiveVideo] = useState(false);
  const [limits, Setlimits] = useState();
  useEffect(() => {
    props.setShowLoader(true);
    async function Child_Featured() {
      await axios({
        method: "get",
        url: url1 + `getfcdata/`,
        params: {
          page: currentpage,
          limit: limited,
        },
      })
        .then((response) => {
          Setlimits(limited);
          setLoader(true);
          // console.log(response.data.data.data, "child featured videoapi");
          // props.setShowLoader(true);
          props.setShowLoader(true);
          setTimeout(() => {
            props.setShowLoader(false);
            // setUserDatas(response.data.data.data);
          }, 60);
          setTimeout(() => {
            setUserDatas(response.data.data.data);
          }, 100);

          setTbaleRefresh(true);

          console.log(tableRefresh, "sd");
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
    if (loader || !refresh || tableRefresh) {
      Child_Featured();
      // console.log(loader,"rendered");
      // console.log(refresh,"refreshered ");
      console.log(tableRefresh, "table");
    }
  }, [loader, loadingData1, currentpage, limited, refresh, tableRefresh]);

  // ------------------To----Remove--------------------
  const handleFeedbackPopupClose = () => {
    setFeedbackPopup(false);
    setFeedbackPopupToggle(false);
  };
  const handleFeedback = (feedbackData) => {
    setFeedBack(feedbackData);
    console.log(feedBack, "From published");
  };

  const toggleFeatured = (id, email, feature) => {
    if (feature) {
      setId(id);
      setEmail(email);
      setChild(false);
      setFeatured(feature);
      setFeedbackPopup(!feedbackPopup);
    } else {
      setId(id);
      setEmail(email);
      setChild(true);
      setFeatured(feature);
      setFeedbackPopupToggle(!feedbackPopupToggle);
    }
  };

  const toggleChildrenFeatured = (id, email, child) => {
    if (child) {
      setId(id);
      setEmail(email);
      setFeatured(false);
      setChild(child);
      setFeedbackPopup(!feedbackPopup);
    } else {
      setId(id);
      setEmail(email);
      setChild(child);
      setFeatured(true);
      setFeedbackPopupToggle(!feedbackPopupToggle);
    }
  };
  const setLoadingData = (setLoadingData) => {
    setLoadingData1(true);
  };

  const toggleVerification = (id, email, verify) => {
    setId(id);
    setEmail(email);
    setVerify(verify);
    setVerifyToggle(1);
  };

  // featured video
  useEffect(() => {
    // console.log("YYYYYYYYYYYY",featuredVideo)
    if (Object.keys(featuredStatus).length !== 0) {
      // console.log("kkkkkkkkkkkkk", featuredStatus);

      localStorage.setItem("featuredStatus", JSON.stringify(featuredStatus));
    }
  }, [featuredStatus]);

  useEffect(() => {
    const storedStatus2 = localStorage.getItem("featuredStatus");
    if (storedStatus2) {
      setFeaturedStatus(JSON.parse(storedStatus2));
    }
  }, []);

  // Child video
  useEffect(() => {
    // console.log("YYYYYYYYYYYY",featuredVideo)
    if (Object.keys(childrenFeaturedStatus).length !== 0) {
      // console.log("kkkkkkkkkkkkk", childrenFeaturedStatus);

      localStorage.setItem(
        "childrenFeaturedStatus",
        JSON.stringify(childrenFeaturedStatus)
      );
    }
  }, [childrenFeaturedStatus]);

  useEffect(() => {
    const storedStatus3 = localStorage.getItem("childrenFeaturedStatus");
    if (storedStatus3) {
      setChildrenFeaturedStatus(JSON.parse(storedStatus3));
    }
  }, []);

  const test = (val) => {
    window.open(val);
    activeVideo = true;
    //  setActiveVideo(true)
  };

  // -------------Table Needed----STMT---------------------
  // ----------------------REACT----TABLE---------------------
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "churchName",
        accessor: "churchName",
      },
      {
        Header: "leaderName",
        accessor: "leaderName",
      },
      {
        Header: "email",
        accessor: "email",
      },
      {
        Header: "location",
        accessor: "location",
      },
      {
        Header: "country",
        accessor: "country",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
      },
      {
        Header: "Suggestions",
        accessor: "suggestions",
      },
      {
        Header: "Video File",
        accessor: "videoFile",
        Cell: ({ row, value }) => (
          <Button
            className={`bbb  testing ${activeVideo ? "active" : ""}`}
            variant="contained"
            color="primary"
            component="span"
            onClick={() => {
              // window.open(value);
              // setActiveVideo(true)
              // console.log(row,"active");
              test(row.original.videoFile);
            }}
            startIcon={<OndemandVideoIcon />}
          ></Button>
        ),
      },
      {
        Header: "Image File",
        accessor: "imageFile",
        Cell: ({ value, row }) => (
          <Button
            className="bbb"
            variant="contained"
            color="primary"
            component="span"
            onClick={() => {
              window.open(value);
            }}
            startIcon={<PhotoSizeSelectActualIcon />}
          ></Button>
        ),
      },
      {
        Header: "Verification Status",
        accessor: "verificationStatus",
        Cell: ({ value, row }) => (
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked2"
              checked={row.original.verify == true ? true : false}
              onChange={() =>
                toggleVerification(
                  row.original.id,
                  row.original.email,
                  row.original.verify
                )
              }
              // onClick={toggleTestClick}
            />
          </div>
        ),
      },
      {
        Header: "Time",
        accessor: "createdAt",
      },
      {
        Header: "Featured Status",
        accessor: "feature",

        Cell: ({ value, row }) => (
          <>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked2"
                checked={row.original.feature == true ? true : false}
                onChange={() =>
                  toggleFeatured(
                    row.original.id,
                    row.original.email,
                    row.original.feature
                  )
                }
              />
            </div>
          </>
        ),
      },
      {
        Header: `Children Video's`,
        accessor: "child",
        Cell: ({ value, row }) => (
          <>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked2"
                checked={row.original.child == true ? true : false}
                onChange={() =>
                  toggleChildrenFeatured(
                    row.original.id,
                    row.original.email,
                    row.original.child
                  )
                }
              />
            </div>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="published_page_wrapper">
        <div
          className="navbar-wrapper"
          style={{width: "100%", zIndex: "888" }}
        >
          <NavBar />
        </div>
        <div>
          <SideBar />
        </div>
        <div className="published_table_wrapper">
          <h1 className="CF_HEAD">Children's & Featured Table</h1>
          {/* ------------MBD-DATA-TABLE------------ */}
          {userDatas != undefined && (
            <>
              <Table
                data={userDatas}
                columns={columns}
                pagesize={limited}
                setToRender={setToRender}
                loader={loader}
                setLoader={setLoader}
                toRender={toRender}
                setCurrentpage={setCurrentpage}
                setLoadingData1={setLoadingData1}
                setTbaleRefresh={setTbaleRefresh}
                tableRefresh={tableRefresh}
                setUserDatas={setUserDatas}
                limits={limits}
                Setlimits={Setlimits}
              />
            </>
          )}
        </div>
      </div>

      {feedbackPopup ? (
        <Popup
          onClose={handleFeedbackPopupClose}
          handleFeedback={handleFeedback}
          id={id}
          email={email}
          child={child}
          featured={featured}
          featuredStatus={featuredStatus}
          childrenFeaturedStatus={childrenFeaturedStatus}
          setLoadingData={setLoadingData}
          setLoadingData1={setLoadingData1}
          setLoader={setLoader}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : null}

      {feedbackPopupToggle ? (
        <ConformationPopup
          onClose={handleFeedbackPopupClose}
          id={id}
          email={email}
          child={child}
          featured={featured}
          featuredStatus={featuredStatus}
          childrenFeaturedStatus={childrenFeaturedStatus}
          setLoadingData={setLoadingData}
          setLoadingData1={setLoadingData1}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : null}
    </>
  );
};

export default ChildFeatured;
