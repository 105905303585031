import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import "../../StyleSheets/Temp.scss";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import Switch from "@mui/material/Switch";
import { Button } from "@material-ui/core";
import { data } from "jquery";
import DeletePopup from "./DeletePopup";
import VerifyPopup from "./VerifyPopup";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { url1 } from "../../content/data";
import Swal from "sweetalert2";

const DataTable = (props) => {
  const [activeRows, setActiveRows] = useState([]);

  const [userDataTable, setUserDataTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationData, setConfirmationData] = useState({
    id: null,
    email: "",
    verify: false,
  });

  const [verificationStatus, setVerificationStatus] = useState({});
  const [loadingData1, setLoadingData1] = useState(true);

  useEffect(() => {
    if (Object.keys(verificationStatus).length !== 0) {
      localStorage.setItem(
        "verificationStatus",
        JSON.stringify(verificationStatus)
      );
    }
  }, [verificationStatus]);

  useEffect(() => {
    const storedStatus = localStorage.getItem("verificationStatus");
    if (storedStatus) {
      setVerificationStatus(JSON.parse(storedStatus));
    }
  }, []);

  const Swal = require("sweetalert2");

  useEffect(() => {
    // props.setShowLoader(true);
    async function Tabledata1() {
      props.setShowLoader(true);
      await axios({
        method: "get",
        url: url1 + `getalluserdata?page=${currentPage}&limit=${perPage}`,
        // params: {
        //   limit: 10,
        //   offset: 0,
        // },
        // headers: {
        //   "x-access-token": localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          // console.log(response.data.data.data, "$$$$user upload data table");
          // setUserDataTable(response.data.data.data);
          setLoadingData1(false);
          // console.log("table component");
          setTimeout(() => {
            props.setShowLoader(false);
          }, 40);
          setTimeout(() => {
            setUserDataTable(response.data.data.data);
          }, 60);
        })
        .catch((error) => {
          // console.log(error, "@@@@nooooooo data table");
        });
    }
    if (loadingData1) {
      Tabledata1();
    }
  }, [loadingData1, currentPage, perPage]);

  // const toggleVerification = (id, email, verify) => {
  //   setConfirmationData({
  //     id: id,
  //     email: email,
  //     verify: verify,
  //   });
  //   setShowConfirmation(true);

  //   const updatedStatus = { ...verificationStatus };
  //   updatedStatus[id] = !updatedStatus[id];
  //   // updatedStatus[email] = !updatedStatus[email];

  //   setVerificationStatus(updatedStatus);

  //   const form_data = new FormData();
  //   form_data.append("id", id);
  //   form_data.append("verify", !verify);
  //   form_data.append("to", email);

  //   // Update the verification status on the server using PUT request
  //   axios({
  //     method: "put",
  //     url: url1 + `updateuserdata`,
  //     data: form_data,
  //   })
  //     .then((response) => {
  //       setVerificationStatus(updatedStatus); // Update the state after successful API call
  //       // setVerificationStatus({});
  //       setLoadingData1(true);
  //     })
  //     .catch((error) => {
  //       console.error("Error updating verification status:", error);
  //     });
  // };

  const columns = [
    // {
    //   label: "ID",
    //   field: "id",
    //   sort: "asc",
    // },
    {
      label: "Church Name",
      field: "churchName",
      sort: "asc",
    },
    {
      label: "Leader Name",
      field: "leaderName",
      sort: "asc",
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
    },
    {
      label: "Location",
      field: "location",
      sort: "asc",
    },
    {
      label: "Country",
      field: "country",
      sort: "asc",
    },
    {
      label: "Mobile",
      field: "mobile",
      sort: "asc",
    },
    {
      label: "Suggestions",
      field: "suggestions",
      sort: "asc",
    },
    {
      label: "Video File",
      field: "videoFile",
      sort: "asc",
    },
    {
      label: "Image File",
      field: "imageFile",
      sort: "asc",
    },
    {
      label: "Verification Status",
      field: "verificationStatus",
      sort: "asc",
    },
    {
      label: "Time",
      field: "createdAt",
      sort: "asc",
    },
    {
      label: "Delete",
      field: "deletee",
      sort: "asc",
    },
  ];

  // SMH
  const [id, setId] = useState("");
  const [feedbackPopupToggle, setFeedbackPopupToggle] = useState(0);
  const [feedbackPopup, setFeedbackPopup] = useState(0);
  const [VerifyToggle, setVerifyToggle] = useState(0);
  const [email, setEmail] = useState("");
  const [verify, setVerify] = useState("");

  const [forID, setIDS] = useState("");

  const toggleVerification = (id, email, verify) => {
    setId(id);
    setEmail(email);
    setVerify(verify);
    setVerifyToggle(1);
    // setVerifyToggle((prevToggle) => (prevToggle === 1 ? 0 : 1));
    console.log(VerifyToggle, "tltl");
  };

  useEffect(() => {
    if (VerifyToggle) {
      // Popup should open when VerifyToggle is truthy
      console.log(VerifyToggle, "tltl");
    }
  }, [VerifyToggle]);

  const setLoadingData = (setLoadingData) => {
    setLoadingData1(true);
  };

  const handleFeedbackPopupClose = () => {
    setFeedbackPopup(false);
    setFeedbackPopupToggle(false);

    setVerifyToggle(0);
  };
  const handleDelete = (id) => {
    // console.log(id, "ids");
    setIDS(id);

    // console.log(forID, "for");
    setFeedbackPopupToggle(1);
  };

  const toSubmits = () => {
    try {
      const response = axios({
        method: "put",
        url: url1 + "updateuserdata",
        data: {
          delete: true,
          id: forID,
        },
      });
      setTimeout(() => {
        setLoadingData1(true);
      }, 20);

      console.log(response, "res");
      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log("I was closed by the timer");
          setLoadingData1(true);
        }
      });
    } catch (error) {
      console.log(error, "error in delete");
    }
  };

  const handleTime = (timestamp) => {
    // "2023-08-07 06:02:05"
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      // second: '2-digit',
      hour12: true,
    });

    return formattedDate;
  };

  const rows = userDataTable.map((item) => ({
    id: item.id,
    churchName: item.churchName,
    leaderName: item.leaderName,
    email: item.email,
    // videoFile: (
    //   <Button
    //     className="bbb"
    //     variant="contained"
    //     color="primary"
    //     component="span"
    //     onClick={(e) => {
    //       window.open(item.videoFile);
    //     }}
    //     startIcon={<OndemandVideoIcon />}
    //   ></Button>
    // ),
    // imageFile: (
    //   <Button
    //     className="bbb"
    //     variant="contained"
    //     color="primary"
    //     component="span"
    //     onClick={(e) => {
    //       window.open(item.imageFile);
    //     }}
    //     startIcon={<PhotoSizeSelectActualIcon />}
    //   ></Button>
    // ),

    videoFile: (
      <Button
        style={{
          backgroundColor: activeRows.includes(item.id) ? "#43B841" : "#3F51B5",
        }}
        className="bbb"
        variant="contained"
        color="primary"
        component="span"
        onClick={(e) => {
          window.open(item.videoFile);
          // Set the active row to the clicked item's ID
          setActiveRows([item.id]);
        }}
        startIcon={<OndemandVideoIcon />}
      ></Button>
    ),

    imageFile: (
      <Button
        style={{
          backgroundColor: activeRows.includes(item.id) ? "#43B841" : "#3F51B5",
        }}
        className="bbb"
        variant="contained"
        color="primary"
        component="span"
        onClick={(e) => {
          window.open(item.imageFile);
          // Set the active row to the clicked item's ID
          setActiveRows([item.id]);
        }}
        startIcon={<PhotoSizeSelectActualIcon />}
      ></Button>
    ),

    location: item.location,
    country: item.country,
    mobile: item.mobile,
    suggestions: item.suggestions,
    verificationStatus: (
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
          checked={item.verify == true ? true : false}
          onChange={() => toggleVerification(item.id, item.email, item.verify)}
        />
      </div>
    ),
    createdAt: handleTime(item.createdAt),
    deletee: (
      <div style={{ width: "100px" }}>
        <button className="deleteButton" onClick={() => handleDelete(item.id)}>
          Delete
        </button>
      </div>
    ),
  }));

  const tableData = {
    columns,
    rows,
  };

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject + 1);

    console.log(selectedObject, "pap");
  };

  var totalRowCount = rows.length;
  localStorage.setItem("tableLength", JSON.stringify(totalRowCount));

  return (
    <div className="user_uploaddata_table_wrapper">
      {/* <h1>Uploaded Videos Table</h1> */}
      <MDBDataTable
        className="custom-datatable"
        id="tableId"
        hover
        bordered
        responsive
        noBottomColumns
        searching={true}
        sortable={false}
        info={false}
        data={tableData}
        paging={true}
        onPageChange={handlePageChange}
        paginationLabel={["Prev", "Next"]}
        // paginationPerPage={perPage}
        entries={perPage}
        // displayEntries={[5, 10, 15, 20]}
        noRecordsFoundLabel="No data found in the table."
      />
      {feedbackPopupToggle ? (
        <DeletePopup
          onClose={handleFeedbackPopupClose}
          id={id}
          onSubmit={toSubmits}
          setLoadingData={setLoadingData}
        />
      ) : null}
      {VerifyToggle ? (
        <VerifyPopup
          onClose={handleFeedbackPopupClose}
          id={id}
          email={email}
          verify={verify}
          setLoadingData={setLoadingData}
          verificationStatus={verificationStatus}
        />
      ) : null}
    </div>
  );
};

export default DataTable;
