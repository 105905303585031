import "./App.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/AdminLogin/Login";
import Forget from "./Components/AdminLogin/Forget";
import CategoryList from "./Components/Category/CategoryList";
import Loader from "./Components/AppComponent/Loader";
import { useState, useEffect } from "react";
import Published from "./Components/Tables/Published";
import Featured from "./Components/Tables/Featured";
import ChildrensVideo from "./Components/Tables/childrensVideo";
import NewPwd from "./Components/AdminLogin/NewPwd";
import ChildFeatured from "./Components/Tables/ChildFeatured";

function App() {
  const [ShowLoader, setShowLoader] = useState(false);

  useEffect(() => {
    handleChnage("1");
  }, []);

  const handleChnage = (e) => {
    var browserZoomLevel = Math.round(
      (window.outerWidth / window.innerWidth) * 100
    );
    // console.log(browserZoomLevel, window.innerHeight, window.innerWidth);
    var doc = document.getElementsByClassName("App");
    let zoom = (window.innerWidth / 2000) * 100;
    // console.log(Math.round(zoom));
    doc[0].style.zoom = `${Math.round(zoom)}%`;
  };

  window.addEventListener("resize", handleChnage);

  return (
    <div className="App">
      {ShowLoader ? <Loader></Loader> : ""}
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Login
                ShowLoader={ShowLoader}
                setShowLoader={setShowLoader}
              ></Login>
            }
          ></Route>
          <Route
            path="/dashboard"
            element={
              <Dashboard
                ShowLoader={ShowLoader}
                setShowLoader={setShowLoader}
              ></Dashboard>
            }
          ></Route>
          <Route path="/forget" element={<Forget></Forget>}></Route>
          <Route
            path="/category"
            element={<CategoryList></CategoryList>}
          ></Route>
          <Route
            path="/published"
            element={
              <Published
                ShowLoader={ShowLoader}
                setShowLoader={setShowLoader}
              ></Published>
            }
          ></Route>
          <Route
            path="/featured"
            element={
              <Featured
                ShowLoader={ShowLoader}
                setShowLoader={setShowLoader}
              ></Featured>
            }
          ></Route>
          <Route
            path="/children-videos"
            element={
              <ChildrensVideo
                ShowLoader={ShowLoader}
                setShowLoader={setShowLoader}
              />
            }
          ></Route>
          <Route
            path="/children-Featured"
            element={
              <ChildFeatured
                ShowLoader={ShowLoader}
                setShowLoader={setShowLoader}
              />
            }
          ></Route>
          <Route path="/NewPwd" element={<NewPwd />}></Route>
          <Route path="/updatepwd" element={<NewPwd />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
