import React from 'react'

const Footer = () => {
  return (
    <div className='footer_wrapper'>
        <div className=''>
            <p>Powered By SaveAllNations @ 2023</p>
        </div>
    </div>
  )
}

export default Footer;
