import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import "../../StyleSheets/Featured.scss";

import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import Switch from "@mui/material/Switch";
import { Button } from "@material-ui/core";
import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import { url1 } from "../../content/data";


const Published = (props) => {
  // show upload data in map GET api integration
  const [userDataTable, setUserDataTable] = useState([]);
  const [markersData, setMarkersData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(40);

  const [featuredStatus, setFeaturedStatus] = useState({});
  const [loadingData1, setLoadingData1] = useState(true);


  // featured video
  useEffect(() => {
    // console.log("YYYYYYYYYYYY",featuredVideo)
    if (Object.keys(featuredStatus).length !== 0) {
      // console.log("kkkkkkkkkkkkk", featuredStatus);

      localStorage.setItem(
        "featuredStatus",
        JSON.stringify(featuredStatus)
      );
    }
  }, [featuredStatus]);

  useEffect(() => {
    const storedStatus2 = localStorage.getItem("featuredStatus");
    if (storedStatus2) {
      setFeaturedStatus(JSON.parse(storedStatus2));
    }
  }, []);



  useEffect(() => {
    props.setShowLoader(true);
    async function Tabledata1() {
      await axios({
        method: "get",
        url: url1 + `getfeatureduserdata?page=${currentPage}&limit=${perPage}&feature=1`,
        // params: {
        // //   limit: 10,
        // //   offset: 0,
        // },
        // headers: {s
        //   "x-access-token": localStorage.getItem("token"),
        // },
      }).then((response) => {
        // console.log("$how$how upload data table", response.data.data.data);
        // setMarkersData(response.data.data.data);
        // setUserDataTable(response.data.data.data);
        setLoadingData1(false);
        setTimeout(() => {
          props.setShowLoader(false);
        }, 100);
       
        setTimeout(()=>{
          setMarkersData(response.data.data.data);
          setUserDataTable(response.data.data.data);
        }, 170)
      })
        .catch((error) => {
          console.log(error, ">>><<><><>< data table");
        });
    }
    if (loadingData1) {
      Tabledata1();
    }
  }, [loadingData1, currentPage, perPage]);


  //   useEffect(() => {
  //     fetchMapData();
  //   }, [currentPage, perPage]);

  // users datas table
  //   const fetchMapData = () => {
  //     axios({
  //       method: "get",
  //       url: `http://localhost:8002/getverifyuserdata?page=${currentPage}&limit=${perPage}&verify=1`,
  //     })
  //       .then((response) => {
  //         console.log("$how$how upload data table", response.data.data.data);
  //         setMarkersData(response.data.data.data);
  //         setUserDataTable(response.data.data.data);
  //       })
  //       .catch((error) => {
  //         console.log(error, ">>><<><><>< data table");
  //       });
  //   };


  const toggleFeatured = (id, email, feature) => {
    const updatedStatus2 = { ...featuredStatus };
    updatedStatus2[id] = !updatedStatus2[id];
    updatedStatus2[email] = !updatedStatus2[email];
    setFeaturedStatus(updatedStatus2);

    const form_data = new FormData();
    form_data.append("id", id);
    form_data.append("feature", !feature);
    form_data.append("to", email);

    // console.log("mmmmmmmmmmm", updatedStatus2, feature);

    // Update the verification status on the server using PUT request
    axios({
      method: "put",
      url: url1 + `updateuserdata`,
      data: form_data,
    })
      .then((response) => {
        // console.log(response, "Featured status updated successfully");
        setFeaturedStatus({}); // Update the state after successful API call
        setLoadingData1(true);
        

      })
      .catch((error) => {
        console.error("Error updating featured status:", error);
      });
  };

  const columns = [
    {
      label: "ID",
      field: "id",
      sort: "asc",
    },
    {
      label: "Church Name",
      field: "churchName",
      sort: "asc",
    },
    {
      label: "Leader Name",
      field: "leaderName",
      sort: "asc",
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
    },
    {
      label: "Location",
      field: "location",
      sort: "asc",
    },
    {
      label: "Country",
      field: "country",
      sort: "asc",
    },
    {
      label: "Mobile",
      field: "mobile",
      sort: "asc",
    },
    {
      label: "Suggestions",
      field: "suggestions",
      sort: "asc",
    },
    {
      label: "Video File",
      field: "videoFile",
      sort: "asc",
    },
    {
      label: "Image File",
      field: "imageFile",
      sort: "asc",
    },

    // {
    //   label: "Action",
    //   field: "action",
    //   sort: "asc",
    // },
  ];
  const handleAction = (userId) => {
    // Perform your custom action here
    // console.log(`Performing action for user with ID: ${userId}`);
  };

  const rows = userDataTable.map((item) => ({
    id: item.id,
    churchName: item.churchName,
    leaderName: item.leaderName,
    email: item.email,
    videoFile: (
      <Button
        className="bbb"
        variant="contained"
        color="primary"
        component="span"
        onClick={(e) => {
          window.open(item.videoFile);
        }}
        startIcon={<OndemandVideoIcon />}
      ></Button>
    ),
    imageFile: (
      <Button
        className="bbb"
        variant="contained"
        color="primary"
        component="span"
        onClick={(e) => {
          window.open(item.imageFile);
        }}
        startIcon={<PhotoSizeSelectActualIcon />}
      ></Button>
    ),
    location: item.location,
    country: item.country,
    mobile: item.mobile,
    suggestions: item.suggestions,
    // featuredStatus: (
    //     <div class="form-check form-switch">
    //       <input
    //         class="form-check-input"
    //         type="checkbox"
    //         role="switch"
    //         id="flexSwitchCheckChecked2"
    //         checked={item.feature==true?true :  false}
    //         // checked={featuredStatus?.[item.id] || false}
    //         onChange={() => toggleFeatured(item.id, item.email, item.feature)}
    //       />
    //     </div>
    //   ),
  }));

  const tableData = {
    columns,
    rows,
  };

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected + 1);
  };
  return (
    <div className="published_page_wrapper">
      <div className="navbar-wrapper" style={{ width: "100%", zIndex: "888"}}>
        <NavBar />
      </div>
      <div>
        <SideBar />
      </div>
      <div className="published_table_wrapper">
        <h1>Featured Videos Table</h1>
        <MDBDataTable
          className="custom-datatable"
          hover
          // striped
          bordered
          responsive
          noBottomColumns
          searching={true}
          sortable={false}
          info={false}
          data={tableData}
          paging={true}
          onPageChange={handlePageChange}
          paginationLabel={["Prev", "Next"]}
          // paginationPerPage={perPage}
          // displayEntries={[5, 10, 15, 20]}
          noRecordsFoundLabel="No data found in the table."
        />
      </div>
    </div>
  );
};

export default Published;
