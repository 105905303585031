import React from 'react';
import loader from '../../assets/loader.gif';
// import loader from '../../assets/pointer.gif';
import '../../StyleSheets/Loader.scss';


const Loader = () => {
  return (
    <div className='loader_wrapper'>
        <div className='loader_inner_wrapper'>
        <img src={loader}></img>
        </div>
    </div>
  )
}

export default Loader;