import React from "react";
import "../../StyleSheets/Category/CategoryList.scss";
import pslamsGif from "../../assets/images/pslamsGif.gif";
import saveAll from "../../assets/images/saveAll.png";


const CategoryList = () => {
  return (
    <div className="category_wrapper">
        <div className="outbor">
          <nav nav className="navbar navbar-expand-sm">
            <div className="container-fluid navbarHeadC">
              <img className="saveAllLogo" src={saveAll} alt="saveallimg" />
            </div>
            <div id="navbarCollapse" className="collapse navbar-collapse ">
              <div className="pslamLogo">
                <img src={pslamsGif} alt="pslam" width="54px" />
              </div>
            </div>
          </nav>
        </div>
      <div className="container-fluid course_wrapper">
        <div className="row">
          <div className="col-md-4">
            <div className="pending-list">
                <h1>Upload</h1>
            </div>
          </div>
          <div className="col-md-4">
            <div className="published-list">
            <h1>Published</h1>

            </div>
          </div>
          <div className="col-md-4">
          <div className="rejected-list">
          <h1>Featured Video</h1>

                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryList;
