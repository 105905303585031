import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { url1 } from "../../content/data";
import "../../StyleSheets/Featured.scss";
import Swal from "sweetalert2";

function Popup(props) {
  const { ShowLoader, setShowLoader } = props;
  const [show, setShow] = useState(true);
  // const [feedBackValue, setfeedBackValue] = useState("");
  const [feedbackInputValue, setFeedbackInputValue] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const {
    handleFeedback,
    id,
    email,
    child,
    featuredStatus,
    childrenFeaturedStatus,
    setLoadingData,
    featured,
    setLoadingData1,
    setLoader,
    setRefresh,
    refresh,
  } = props;

  const Swal = require("sweetalert2");

  useEffect(() => {
    console.log("ds");
  }, []);
  const handleClose = () => {
    setShow(false);
    props.onClose();
  };

  const ChangeFeedBack = (e) => {
    const newValue = e.target.value;
    setFeedbackInputValue(newValue);
    setIsSaveButtonDisabled(newValue === "");
  };

  //SMH Handle Submit.
  const handleSubmit = (e) => {
    props.setShowLoader(true);
    if (child && !featured) {
      const updatedStatus3 = { ...childrenFeaturedStatus };
      updatedStatus3[id] = !updatedStatus3[id];
      console.log(updatedStatus3, !child, email, "333 check....");
      const form_data = new FormData();
      form_data.append("id", id);
      form_data.append("c_fed", feedbackInputValue);
      form_data.append("type", "child");
      form_data.append("child", !child);
      form_data.append("email", email);
      form_data.append("feedback", feedbackInputValue);

      axios({
        method: "put",
        url: url1 + `removefc`,
        data: form_data,
      })
        .then((response) => {
          setLoadingData(true);
          handleClose();
          setLoadingData1(true);
          setLoader(true);
          setTimeout(() => {
            setRefresh(!refresh);
          }, 10);
          setTimeout(() => {
            props.setShowLoader(false);
          }, 40);

          Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.fire("Saved!", "", "success");
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });
          console.log(refresh, "fff");
        })
        .catch((error) => {
          console.error("Error updating featured status:", error);
        });
    } else if (!child && featured) {
      const updatedStatus2 = { ...featuredStatus };
      updatedStatus2[id] = !updatedStatus2[id];
      console.log(!featured, email, "333 hsaj....");
      const form_data = new FormData();
      form_data.append("id", id);
      form_data.append("f_fed", feedbackInputValue);
      form_data.append("type", "feature");
      form_data.append("feature", !featured);
      form_data.append("email", email);
      form_data.append("feedback", feedbackInputValue);
      axios({
        method: "put",
        url: url1 + `removefc`,
        data: form_data,
      })
        .then((response) => {
          setLoadingData(true);
          handleClose();
          setLoader(true);
          setLoadingData1(true);
          setTimeout(() => {
            setRefresh(!refresh);
          }, 100);
          setTimeout(() => {
            props.setShowLoader(false);
          }, 40);
          console.log(refresh, "ccc");
        })
        .catch((error) => {
          console.error("Error updating featured status:", error);
        });
    }
    // handleFeedback(feedbackInputValue);
  };

  return (
    <>
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className="modelman"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="lable">
                Please mention the reason:
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className="feedBackTextBox"
                onChange={ChangeFeedBack}
                required={true}
                placeholder="Please indicate the cause."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="save-button"
            onClick={handleSubmit}
            disabled={isSaveButtonDisabled}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Popup;
