import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import "../../StyleSheets/Published.scss";
import { url1 } from "../../content/data";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import Switch from "@mui/material/Switch";
import { Button } from "@material-ui/core";
import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import Popup from "./popup";
import ConformationPopup from "./ConformationPopup";

const Published = (props) => {
  const { ShowLoader, setShowLoader } = props;

  const [refresh, setRefresh] = useState(true);

  // show upload data in map GET api integration
  const [userDataTable, setUserDataTable] = useState([]);
  const [markersData, setMarkersData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const [featuredStatus, setFeaturedStatus] = useState({});
  const [childrenFeaturedStatus, setChildrenFeaturedStatus] = useState({});

  const [loadingData1, setLoadingData1] = useState(true);
  const [feedbackPopup, setFeedbackPopup] = useState(0);
  const [feedbackPopupToggle, setFeedbackPopupToggle] = useState(0);
  const [feedBack, setFeedBack] = useState("");
  const [feedBackStatus, setFeedBackStatus] = useState(false);

  // featured video
  useEffect(() => {
    // console.log("YYYYYYYYYYYY",featuredVideo)
    if (Object.keys(featuredStatus).length !== 0) {
      // console.log("kkkkkkkkkkkkk", featuredStatus);

      localStorage.setItem("featuredStatus", JSON.stringify(featuredStatus));
    }
  }, [featuredStatus]);

  useEffect(() => {
    const storedStatus2 = localStorage.getItem("featuredStatus");
    if (storedStatus2) {
      setFeaturedStatus(JSON.parse(storedStatus2));
    }
  }, []);

  // Child video
  useEffect(() => {
    // console.log("YYYYYYYYYYYY",featuredVideo)
    if (Object.keys(childrenFeaturedStatus).length !== 0) {
      // console.log("kkkkkkkkkkkkk", childrenFeaturedStatus);

      localStorage.setItem(
        "childrenFeaturedStatus",
        JSON.stringify(childrenFeaturedStatus)
      );
    }
  }, [childrenFeaturedStatus]);

  useEffect(() => {
    const storedStatus3 = localStorage.getItem("childrenFeaturedStatus");
    if (storedStatus3) {
      setChildrenFeaturedStatus(JSON.parse(storedStatus3));
    }
  }, []);

  useEffect(() => {
    props.setShowLoader(true);
    async function Tabledata1() {
      await axios({
        method: "get",
        url:
          url1 +
          `getverifyuserdata?page=${currentPage}&limit=${perPage}&verify=1`,
      })
        .then((response) => {
          console.log("$how$how upload data table", response.data.data.data);
          // setMarkersData(response.data.data.data);
          // setUserDataTable(response.data.data.data);
          setLoadingData1(false);
          setTimeout(() => {
            props.setShowLoader(false);
          }, 100);
          setTimeout(() => {
            setMarkersData(response.data.data.data);
            setUserDataTable(response.data.data.data);
          }, 170);
        })
        .catch((error) => {
          // console.log(error, ">>><<><><>< data table");
        });
    }
    if (loadingData1) {
      Tabledata1();
    }
  }, [loadingData1, currentPage, perPage]);

  const handleFeedback = (feedbackData) => {
    setFeedBack(feedbackData);
    console.log(feedBack, "From published");
  };

  const handleFeedbackPopupClose = () => {
    setFeedbackPopup(false);
    setFeedbackPopupToggle(false);
  };

  // SMH feature status update
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [child, setChild] = useState(null);
  const [featured, setFeatured] = useState(null);

  console.log(featured, "fff");

  const toggleFeatured = (id, email, feature) => {
    console.log(id, "iii");
    if (feature) {
      setId(id);
      setEmail(email);
      setChild(false);
      setFeatured(feature);
      setFeedbackPopup(!feedbackPopup);
    } else {
      setId(id);
      setEmail(email);
      setChild(true);
      setFeatured(feature);
      setFeedbackPopupToggle(!feedbackPopupToggle);
      // setFeedbackPopupToggle(true);
    }
  };

  const toggleChildrenFeatured = (id, email, child) => {
    if (child) {
      console.log(child, "inner");
      setId(id);
      setEmail(email);
      setFeatured(false);
      setChild(child);
      setFeedbackPopup(!feedbackPopup);
    } else {
      setId(id);
      setEmail(email);
      setChild(child);
      setFeatured(true);
      setFeedbackPopupToggle(!feedbackPopupToggle);
    }
  };

  const setLoadingData = (setLoadingData) => {
    setLoadingData1(true);
  };

  const columns = [
    {
      label: "ID",
      field: "id",
      sort: "asc",
    },
    {
      label: "Church Name",
      field: "churchName",
      sort: "asc",
    },
    {
      label: "Leader Name",
      field: "leaderName",
      sort: "asc",
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
    },
    {
      label: "Location",
      field: "location",
      sort: "asc",
    },
    {
      label: "Country",
      field: "country",
      sort: "asc",
    },
    {
      label: "Mobile",
      field: "mobile",
      sort: "asc",
    },
    {
      label: "Suggestions",
      field: "suggestions",
      sort: "asc",
    },
    {
      label: "Video File",
      field: "videoFile",
      sort: "asc",
    },
    {
      label: "Image File",
      field: "imageFile",
      sort: "asc",
    },
    {
      label: "Featured Status",
      field: "featuredStatus",
      sort: "asc",
    },
    {
      label: "Children's Videos",
      field: "childrenFeaturedStatus",
      sort: "asc",
    },
  ];
  const toggleTestClick = () => {
    // Perform your custom action here
    console.log(`Performing action for user with ID:`);
    if (userDataTable.feature === true) {
      setFeedbackPopup(!feedbackPopup);
    }
  };

  const rows = userDataTable.map((item) => ({
    id: item.id,
    churchName: item.churchName,
    leaderName: item.leaderName,
    email: item.email,
    videoFile: (
      <Button
        className="bbb"
        variant="contained"
        color="primary"
        component="span"
        onClick={(e) => {
          window.open(item.videoFile);
        }}
        startIcon={<OndemandVideoIcon />}
      ></Button>
    ),
    imageFile: (
      <Button
        className="bbb"
        variant="contained"
        color="primary"
        component="span"
        onClick={(e) => {
          window.open(item.imageFile);
        }}
        startIcon={<PhotoSizeSelectActualIcon />}
      ></Button>
    ),
    location: item.location,
    country: item.country,
    mobile: item.mobile,
    suggestions: item.suggestions,
    featuredStatus: (
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked2"
          checked={item.feature == true ? true : false}
          onChange={() => toggleFeatured(item.id, item.email, item.feature)}
          // onClick={toggleTestClick}
        />
      </div>
    ),
    childrenFeaturedStatus: (
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked2"
          checked={item.child == true ? true : false}
          onChange={() =>
            toggleChildrenFeatured(item.id, item.email, item.child)
          }
          disabled={item.feature === true && !child}
        />
      </div>
    ),
  }));

  const tableData = {
    columns,
    rows,
  };

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected + 1);
  };
  return (
    <>
      <div className="published_page_wrapper">
        <div
          className="navbar-wrapper"
          style={{ width: "100%", zIndex: "888" }}
        >
          <NavBar />
        </div>
        <div>
          <SideBar />
        </div>
        <div className="published_table_wrapper">
          <h1>Published Videos Table</h1>
          <MDBDataTable
            className="custom-datatable"
            hover
            bordered
            responsive
            noBottomColumns
            searching={true}
            sortable={false}
            info={false}
            data={tableData}
            paging={true}
            onPageChange={handlePageChange}
            paginationLabel={["Prev", "Next"]}
            // paginationPerPage={perPage}
            // entries={perPage}
            // displayEntries={[5, 10, 15, 20]}
            noRecordsFoundLabel="No data found in the table."
          />
        </div>
      </div>

      {feedbackPopup ? (
        <Popup
          onClose={handleFeedbackPopupClose}
          handleFeedback={handleFeedback}
          id={id}
          email={email}
          child={child}
          featured={featured}
          featuredStatus={featuredStatus}
          childrenFeaturedStatus={childrenFeaturedStatus}
          setLoadingData={setLoadingData}
          setLoadingData1={setLoadingData1}
          ShowLoader={ShowLoader}
          setShowLoader={setShowLoader}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : null}
      {feedbackPopupToggle ? (
        <ConformationPopup
          onClose={handleFeedbackPopupClose}
          id={id}
          email={email}
          child={child}
          featured={featured}
          featuredStatus={featuredStatus}
          childrenFeaturedStatus={childrenFeaturedStatus}
          setLoadingData={setLoadingData}
          setLoadingData1={setLoadingData1}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : null}
    </>
  );
};

export default Published;
