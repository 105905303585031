import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { url1 } from "../../content/data";
import "../../StyleSheets/Featured.scss";

function VerifyPopup(props) {
  const [show, setShow] = useState(true);
  const [feedbackInputValue, setFeedbackInputValue] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { id, email, verify, verificationStatus, setLoadingData } = props;

  const handleClose = () => {
    setShow(false);
    props.onClose();
  };

  //SMH Handle Submit.
  const handleSubmit = (e) => {
    const updatedStatus = { ...verificationStatus };
    updatedStatus[id] = !updatedStatus[id];

    const form_data = new FormData();
    form_data.append("id", id);
    form_data.append("verify", !verify);
    form_data.append("to", email);
    axios({
      method: "put",
      url: url1 + `updateuserdata`,
      data: form_data,
    })
      .then((response) => {
        setLoadingData(true);
        handleClose();
      })
      .catch((error) => {
        console.error("Error updating verification status:", error);
      });
  };

  return (
    <>
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className="modelman"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to verify this record?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
          <button className="save-button" onClick={handleSubmit}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VerifyPopup;
