import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// import "../../StyleSheets/SideBar/SideBar.css";

import "../../StyleSheets/SideBar/SidebarUpdate.scss";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
// import childrenIcon from "../../assets/images/choir.png";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import CategoryIcon from "@mui/icons-material/Category";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import { GrDeploy } from "react-icons/gr";
import { Height } from "@material-ui/icons";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

const SideBar = () => {
  const [viewdrop, setviewdrop] = useState(false);

  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`sidebar-wrapper ${collapsed ? "collapsed" : ""}`}>
      <div className="ham-wrap">
        <button
          id="sidebartoggler"
          className={`hamburger-btn ${collapsed ? "collapsed" : ""}`}
          onClick={toggleSidebar}
        >
          <MenuOpenIcon />
        </button>
      </div>

      <div className="page-wrap">
        <div className="sidebar toggle-btn">
          <div className="admin">
            <h6>Admin Panel</h6>
            <span onClick={(e) => setviewdrop(!viewdrop)}>
              {JSON.parse(localStorage.getItem("adminLogin")).name}
            </span>
          </div>
          <ul>
            <li>
              <NavLink exact to="/dashboard" activeClassName="active">
                <HomeIcon />
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/published" activeClassName="active">
                <ManageAccountsIcon />
                Published
              </NavLink>
            </li>

            <li>
              <NavLink to="/featured" activeClassName="active">
                <FeaturedVideoIcon />
                Featured Video
              </NavLink>
            </li>
            <li>
              <NavLink to="/children-videos" activeClassName="">
                <EscalatorWarningIcon />
                <span>Children's Videos</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/children-Featured" activeClassName="">
                <FamilyRestroomIcon />
                <span>Children's &Featured Video</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
