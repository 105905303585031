import React, { useState } from "react";
import pslamsGif from "../../assets/images/pslamsGif.gif";
import saveAll from "../../assets/images/saveAll.png";
import "../../StyleSheets/NavBar/NavBar.scss";
import $ from "jquery";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";

import NotificationsIcon from "@mui/icons-material/Notifications";

const NavBar = (props) => {
  const [viewdrop, setviewdrop] = useState(false);

  return (
    <div className="outbor">
      <nav nav className="navbar navbar-expand-sm">
        <div className="container-fluid navbarHeadC">
          <img className="saveAllLogo" src={saveAll} alt="saveallimg" />
        </div>

        <div className="nav-option-wrapper">
          <Tooltip title="Published Video Count" arrow>
            <div className="notification-wrapper">
              <NotificationsIcon />
              <span className="badge">
                {/* {localStorage.getItem("tableLength") > 1
                  ? localStorage.getItem("tableLength")
                  : ""} */}
                  {localStorage.getItem("tableLength")}
              </span>
            </div>
          </Tooltip>
        </div>

        <div className="admin-profile">
          <div class="half">
            <label for="profile2" class="profile-dropdown">
              <input type="checkbox" id="profile2" />
              <div className="account-circle">
                {/* <img src={JSON.parse(localStorage.getItem("adminLogin")).imageFile}></img> */}
                <AccountCircleIcon />
              </div>
              <span onClick={(e) => setviewdrop(!viewdrop)}>
                {JSON.parse(localStorage.getItem("adminLogin")).name}
              </span>
              <label for="profile2">
                <i class="mdi mdi-menu"></i>
              </label>
              <ul className="drop-down">
                <li>
                  <a href="#">
                    <i class="mdi mdi-account"></i>Email:&nbsp;
                    {JSON.parse(localStorage.getItem("adminLogin")).email}
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    localStorage.clear("adminLogin");
                    window.location.href = "/";
                  }}
                >
                  <a href="/">
                    <i class="mdi mdi-logout"></i>Logout
                  </a>
                </li>
              </ul>
            </label>
          </div>

          <div id="navbarCollapse" className="collapse navbar-collapse ">
            <div className="pslamLogo">
              <img src={pslamsGif} alt="pslam" width="54px" />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
