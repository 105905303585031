import React, { useEffect } from 'react'
import { useState } from 'react';
import LockIcon from "@mui/icons-material/Lock";
import saveAll from "../../assets/images/saveAll.png";
import "../../StyleSheets/Login/Newpwd.scss";
import $ from "jquery";
import NoEncryptionIcon from "@mui/icons-material/NoEncryption";
import axios from 'axios';
import { url1 } from "../../content/data";

import Swal from 'sweetalert2'

const NewPwd = () => {
    // -------newPasswords-------------------
    const [Newpassword, setnewPwd] = useState();
    const [ConfirmPWD, setConFipwd] = useState();
    const [uniqid, setuniqid] = useState();
    const [error, setError] = useState();
    const [view, setView] = useState(false);
    const[viewConfrim , setViewCom] = useState(false)
    let [passwordERR, setPWDERR] = useState(false);
    let [Confi_err, setCon_Err] = useState(false)
    //------------------Error-Filed-----------------

    const Swal = require('sweetalert2')

    useEffect(() => {
        let id = window.location.href.split("?")[1]
        setuniqid(id)
    }, [])

    //----------------------PWD---INPUTFILEDS----
    const handleNewPwd = (e) => {
        setnewPwd(e.target.value);
        // console.log(e.target.value, "Npwd");
        setPWDERR(false)
        // console.log(passwordERR,"i");

    }
    const handleConfirmPWD = (e) => {
        setConFipwd(e.target.value);
        setCon_Err(false)
        // console.log(e.target.value, " in the confirm password side ");
        if (e.target.value != Newpassword) {
            setCon_Err(true);
            // console.log(ConfirmPWD,"p");
        }
        if (ConfirmPWD === "") {
            setCon_Err(false)
        }
      
    }

    //-----------------------Submit--------
    const HandleSubmit = () => {
        if (Newpassword != 0 && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{5,}$/
            .test(Newpassword)) {
            // console.log(passwordERR,"ds");
        }



        if (ConfirmPWD == Newpassword && ConfirmPWD != undefined && Newpassword != undefined) {

            try {
                const respose = axios({
                    method: "put",
                    url: url1+"updatepwd",
                    data: {
                        encode_id: uniqid,
                        password: ConfirmPWD,
                    }
                })
                // console.log(respose ,"in the axios");
           
            Swal.fire({
                title: 'your are successfully updated',
               
                showCancelButton: true,
                confirmButtonText: 'ok',
               
              }).then((result) => {
                
                if (result.isConfirmed) {
                 
                  window.location.href="/"
                }
              })
            }
            catch (error) {
                console.log(error, "in the erroes");
            }
        }
        if(Newpassword != ConfirmPWD){
           
            Swal.fire("Mismatched password");
            setCon_Err(false);

        }
        
        if (Newpassword === undefined) {
            setPWDERR(true)
            // console.log("sd");
            // console.log(passwordERR, "sd");
        }
        if (ConfirmPWD === undefined) {
            setCon_Err(true)
            //   console.log("sd");
            //   console.log(Confi_err, "sd");
        }
    }

    return (

        <div>

            {/* ------------------------PWDS---------------------------- */}

            <div className="forget_wrapper">
                <div className="outbor">
                    <nav className="navbar navbar-expand-sm">
                        <div className="container-fluid navbarHeadC">
                            <img className="saveAllLogo" src={saveAll} alt="saveallimg" />
                        </div>
                    </nav>
                </div>
                <div className="main">
                    <div className="middle">
                        <div className="inner1">
                            <h2>Password-Change</h2>

                        </div>
                        <div className=" NewPassword">
                            <div className='text-start'>
                                <div className='label'>New password</div>
                            </div>

                            <div className='NewPassword-Filed'>
                                
                                <input
                                className={` Input-Field ${view ? "activate" : "notactivate"}`}
                                    placeholder="password"
                                    onChange={handleNewPwd}
                                    id="NewPWD"
                                    value={Newpassword}
                                    name="Newpassword"
                                    type='text' />
                                   <i className="icons">
                                    {view ? (
                                        <NoEncryptionIcon
                                            onClick={(e) =>{ setView(!view)
                                            }}
                                        ></NoEncryptionIcon>
                                    ) : (
                                        <LockIcon onClick={(e) => {setView(!view)
                                        }}></LockIcon>
                                    )}
                                </i>

                            </div>
                            {passwordERR &&
                                <div
                                    className='text-left'
                                    style={{ color: "red", fontSize: "12px" }}
                                >Enter a password</div>
                            }
                            <br />

                        </div>

                        {/* -------------------------Confirm-passwords------------------- */}

                        <div className=" NewPassword">
                            <div className='text-start'>
                                <div className='label'>Confirm password</div>
                            </div>

                            <div className='NewPassword-Filed'>
                                <input 
                                      className={` Input-Field ${viewConfrim ? "activate" : "notactivate"}`}
                                    placeholder=" confirm-password"
                                    onChange={handleConfirmPWD}
                                    id="ConfirmPWD"
                                    value={ConfirmPWD}
                                    type='text' />
                                     <i className="icons">
                                    {viewConfrim ? (
                                        <NoEncryptionIcon
                                            onClick={(e) =>{setViewCom(!viewConfrim)
                                            }}
                                        ></NoEncryptionIcon>
                                    ) : (
                                        <LockIcon onClick={(e) => {setViewCom(!viewConfrim)
                                        }}></LockIcon>
                                    )}
                                </i>
                            </div>


                            {Confi_err &&
                                <div
                                    className='text-left'
                                    style={{ color: "red", fontSize: "12px" }}
                                >Enter a  valid password</div>
                            }

                        </div>

                        <br />
                        <button class="  btn-restart_CLICK" type="button"
                            onClick={HandleSubmit}
                        >Restart password</button>

                    </div>
                </div>
            </div>



        </div>
    )
}

export default NewPwd
