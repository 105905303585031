import React, { useState, useEffect } from "react";
import "../../StyleSheets/Login/Login.css";
import "../../StyleSheets/Login/Login.scss";

import axios from "axios";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import NoEncryptionIcon from "@mui/icons-material/NoEncryption";

import Navbar from "../NavBar/NavBar";
import pslamsGif from "../../assets/images/pslamsGif.gif";
import saveAll from "../../assets/images/saveAll.png";
import { url1 } from "../../content/data";

const Login = (props) => {
  const [adminLogin, setAdminLogin] = useState({ email: "", password: "" });
  const [view, setView] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleChange = (e) => {
    setAdminLogin((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });

    if (adminLogin.email.includes("@")) {
      setEmailError(false);
    }
  };

  const handleSubmit = () => {
    console.log("%$%$%$%$%$%$$");
    const formdata = new FormData();
    formdata.append("email", adminLogin.email);
    formdata.append("password", adminLogin.password);
    props.setShowLoader(true);

    if (adminLogin.email.length > 3 && adminLogin.email.includes("@")) {
      props.setShowLoader(true);

      setEmailError(false);
      if (adminLogin.password.length > 7) {
        setPasswordError(false);

        axios({
          method: "post",
          url: url1 + "login",
          data: formdata,
        })
          .then((response) => {
            console.log(response, "kkkkkkkkkkk");
            // alert(response,"nknknknk")

            if (response.data.data == 2) {
              props.setShowLoader(false);

              setPasswordError(true);
              debugger;
            } else if (response.data.data == 3) {
              props.setShowLoader(false);
              setEmailError(true);
              // debugger;
            } else {
              props.setShowLoader(false);
              setPasswordError(false);
              setEmailError(false);
              // debugger;

              localStorage.setItem(
                "adminLogin",
                JSON.stringify(response.data.data)
              );
              const items = JSON.parse(
                localStorage.getItem("response.data.data")
              );
              window.location.href = "/dashboard";

              // console.log(response.data, "kkkkkkkkkkkkkkkkkkooooooooooooooooo");
              // debugger;
              // localStorage.setItem("token", response.data.session.token);
            }
          })
          .catch((error) => {
            props.setShowLoader(false);
            console.log(error, "#@@#@#@@@#");
          });
      } else {
        props.setShowLoader(false);

        setPasswordError(true);
      }
    } else {
      props.setShowLoader(false);

      setEmailError(true);
    }
  };

  const handleClick = () => {
    window.location.href = "/forget";
  };

  return (
    <div className="login_wrapper">
      <div className="Form-fields">
      <div>
            <div className="outbor">
              <nav nav className="navbar navbar-expand-sm">
                <div className="container-fluid navbarHeadC">
                  <img className="saveAllLogo" src={saveAll} alt="saveallimg" />
                </div>
                <div id="navbarCollapse" className="collapse navbar-collapse ">
                  <div className="pslamLogo">
                    <img src={pslamsGif} alt="pslam" width="54px" />
                  </div>
                </div>
              </nav>
            </div>
          </div>
        <div className="Form_inner_fileds">
          
          <div class="login">
            <h2>Welcome, Admin!</h2>
            <p>Please log in</p>
            <div>
              <div className="label">EMAIL ADDRESS</div>
              <input
                type={"email"}
                name="email"
                autoComplete="off"
                value={adminLogin.email}
                placeholder="Email"
                onChange={(e) => handleChange(e)}
              ></input>
              <i className="icon">
                <EmailIcon></EmailIcon>
              </i>
              <div
                className={`${emailError ? "show" : "hide"}`}
                style={{ color: "red", fontSize: "12px" }}
              >
                *Enter the Valid Email
              </div>
            </div>
            <div>
              <div className="label">PASSWORD</div>
              <input
                type={`text`}
                className={`${view ? "activate" : "notactivate"}`}
                name="password"
                autoComplete="off"
                value={adminLogin.password}
                placeholder="Password"
                onChange={(e) => handleChange(e)}
              ></input>
              <i className="icon">
                {view ? (
                  <NoEncryptionIcon
                    onClick={(e) => setView(!view)}
                  ></NoEncryptionIcon>
                ) : (
                  <LockIcon onClick={(e) => setView(!view)}></LockIcon>
                )}
              </i>
              <div
                className={`${passwordError ? "show" : "hide"}`}
                style={{ color: "red", fontSize: "12px" }}
              >
                *Enter the Valid Password
              </div>
            </div>

            <div>
              <button
                className="Activate_button"
                onClick={() => handleSubmit()}
              >
                SIGN IN
              </button>
            </div>

            <div class="links">
              <a href="/Forget">Forgot password</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
