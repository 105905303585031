import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { url1 } from "../../content/data";
import "../../StyleSheets/Featured.scss";

function ConformationPopup(props) {
  const [show, setShow] = useState(true);
  const [feedbackInputValue, setFeedbackInputValue] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const {
    id,
    email,
    child,
    featuredStatus,
    childrenFeaturedStatus,
    setLoadingData,
    featured,
    setLoadingData1,
    setLoader,
    setRefresh,
    refresh,
  } = props;

  const handleClose = () => {
    setShow(false);
    props.onClose();
  };

  //SMH Handle Submit.
  const handleSubmit = (e) => {
    console.log("sd", child);

    if (!child && featured) {
      debugger;
      // When user clicks on children toggle.
      const updatedStatus3 = { ...childrenFeaturedStatus };
      updatedStatus3[id] = !updatedStatus3[id];
      const form_data = new FormData();
      form_data.append("id", id);
      form_data.append("child", !child);
      form_data.append("to", email);
      axios({
        method: "put",
        url: url1 + `updateuserdata`,
        data: form_data,
      })
        .then((response) => {
          setLoadingData(true);
          handleClose();
          setLoader(true);
          //   setLoadingData1(true);

          debugger;
          setTimeout(() => {
            setRefresh(!refresh);
          }, 10);
          setTimeout(() => {
            props.setShowLoader(false);
          }, 40);
        })
        .catch((error) => {
          console.error("Error updating featured status:", error);
        });
    }
    // When user clicks on featured toggle.
    else if (child && !featured) {
      debugger;
      const updatedStatus2 = { ...featuredStatus };
      updatedStatus2[id] = !updatedStatus2[id];
      const form_data = new FormData();
      form_data.append("id", id);
      form_data.append("feature", !featured);
      form_data.append("to", email);
      axios({
        method: "put",
        url: url1 + `updateuserdata`,
        data: form_data,
      })
        .then((response) => {
          setLoadingData(true);
          handleClose();
          setLoadingData1(true);

          //   debugger
          //   setTimeout(() => {
          //     debugger
          //     setRefresh(!refresh);
          //   }, 100);
          //   setTimeout(() => {
          //     props.setShowLoader(false);
          //   }, 40);
          console.log(refresh, "rrrrr");
          window.location.reload();
        })
        .catch((error) => {
          window.location.reload();
          console.error("Error updating featured status:", error);
        });
    }
    // handleClose();
  };

  return (
    <>
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className="modelman"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Just to ensure everything is as you'd like, are you ready to go
            ahead and update the status?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
          <button className="save-button" onClick={handleSubmit}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConformationPopup;
