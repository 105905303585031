import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { url1 } from "../../content/data";
import "../../StyleSheets/Featured.scss";

function DeletePopup(props) {
  const [show, setShow] = useState(true);
  const [feedbackInputValue, setFeedbackInputValue] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { id, setLoadingData } = props;

  const handleClose = () => {
    setShow(false);
    props.onClose();
  };

  //SMH Handle Submit.
  const handleSubmits = (e) => {
    handleClose();
    // toSubmits()
    props.onSubmit();
   
        
  };

  return (
    <>
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className="modelman"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this record?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
          <button className="save-button" onClick={handleSubmits}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeletePopup;
