import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import "../../StyleSheets/Featured.scss";

import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import { Button } from "@material-ui/core";
import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import { url1 } from "../../content/data";

const ChildrensVideo = (props) => {
  const [userDataTable, setUserDataTable] = useState([]);
  const [markersData, setMarkersData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(40);

  const [featuredStatus, setFeaturedStatus] = useState({});
  const [loadingData1, setLoadingData1] = useState(true);


  // featured video
  useEffect(() => {
    // console.log("YYYYYYYYYYYY",featuredVideo)
    if (Object.keys(featuredStatus).length !== 0) {
      console.log("kkkkkkkkkkkkk", featuredStatus);

      localStorage.setItem(
        "featuredStatus",
        JSON.stringify(featuredStatus)
      );
    }
  }, [featuredStatus]);

  useEffect(() => {
    const storedStatus2 = localStorage.getItem("featuredStatus");
    if (storedStatus2) {
      setFeaturedStatus(JSON.parse(storedStatus2));
    }
  }, []);


  useEffect(() => {
    props.setShowLoader(true);
    async function Tabledata1() {
      await axios({
        method: "get",
        url: url1 + `getchilduserdata?page=${currentPage}&limit=${perPage}&child=1`,
        // params: {
        // //   limit: 10,
        // //   offset: 0,
        // },
        // headers: {s
        //   "x-access-token": localStorage.getItem("token"),
        // },
      }).then((response) => {
        console.log("$how$how upload data table", response.data.data.data);
        setTimeout(()=>{
          props.setShowLoader(false);
        },100)
        setTimeout(()=>{
          setMarkersData(response.data.data.data);
          setUserDataTable(response.data.data.data);
        }, 170)
        // setMarkersData(response.data.data.data);
        // setUserDataTable(response.data.data.data);
        setLoadingData1(false);
      })
        .catch((error) => {
          console.log(error, ">>><<><><>< data table");
        });
    }
    if (loadingData1) {
      Tabledata1();
    }
  }, [loadingData1, currentPage, perPage]);

  const columns = [
    {
      label: "ID",
      field: "id",
      sort: "asc",
    },
    {
      label: "Church Name",
      field: "churchName",
      sort: "asc",
    },
    {
      label: "Leader Name",
      field: "leaderName",
      sort: "asc",
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
    },
    {
      label: "Location",
      field: "location",
      sort: "asc",
    },
    {
      label: "Country",
      field: "country",
      sort: "asc",
    },
    {
      label: "Mobile",
      field: "mobile",
      sort: "asc",
    },
    {
      label: "Suggestions",
      field: "suggestions",
      sort: "asc",
    },
    {
      label: "Video File",
      field: "videoFile",
      sort: "asc",
    },
    {
      label: "Image File",
      field: "imageFile",
      sort: "asc",
    },
  ];
  const rows = userDataTable.map((item) => ({
    id: item.id,
    churchName: item.churchName,
    leaderName: item.leaderName,
    email: item.email,
    videoFile: (
      <Button
        className="bbb"
        variant="contained"
        color="primary"
        component="span"
        onClick={(e) => {
          window.open(item.videoFile);
        }}
        startIcon={<OndemandVideoIcon />}
      ></Button>
    ),
    imageFile: (
      <Button
        className="bbb"
        variant="contained"
        color="primary"
        component="span"
        onClick={(e) => {
          window.open(item.imageFile);
        }}
        startIcon={<PhotoSizeSelectActualIcon />}
      ></Button>
    ),
    location: item.location,
    country: item.country,
    mobile: item.mobile,
    suggestions: item.suggestions,
  }));

  const tableData = {
    columns,
    rows,
  };

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected + 1);
  };
  return (
    <div className="published_page_wrapper">
      <div className="navbar-wrapper" style={{width: "100%", zIndex: "888"}}>
        <NavBar />
      </div>
      <div>
        <SideBar />
      </div>
      <div className="published_table_wrapper">
        <h1>Children's Videos Table</h1>
        <MDBDataTable
          className="custom-datatable"
          hover
          // striped
          bordered
          responsive
          noBottomColumns
          searching={true}
          sortable={false}
          info={false}
          data={tableData}
          paging={true}
          onPageChange={handlePageChange}
          paginationLabel={["Prev", "Next"]}
          // paginationPerPage={perPage}
          // displayEntries={[5, 10, 15, 20]}
          noRecordsFoundLabel="No data found in the table."
        />
      </div>
    </div>
  );
};
export default ChildrensVideo;




