import React from "react";
import NavBar from "./NavBar/NavBar";
import SideBar from "./SideBar/SideBar";
import Footer from "./Footer/Footer";

import "../StyleSheets/DashBoard.scss";
import UserDataTable from "./Tables/UserDataTable";

import Temp from "./Tables/Temp";

const Dashboard = (props) => {
  const {ShowLoader,setShowLoader} = props
  return (
    <div className="dashboard-wrapper">
      <div className="navbar-wrapper" style={{position: "fixed", width: "100%", zIndex: "888"}}>
        <NavBar />
      </div>

      <div>
      <SideBar />
      </div>

      <div className="dashboard-page">
      {/* <SideBar /> */}
        <div className="container-fluid">
          <div className="row">
            {/* <UserDataTable /> */}
            <Temp ShowLoader={ShowLoader} setShowLoader={setShowLoader} />
          </div>
        </div>
      </div>

      <div className="footer-wrapper">
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
